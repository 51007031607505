const routes = Object.freeze({
    ActivateAccountPage: '/activate-account',
    LoginPage: '/login',
    ForgotPasswordPage: '/forgot-password',
    ResetPasswordPage: '/reset-password',
    RegisterPage: '/register',
    NotFoundPage: '/404',
    ProfilePage: '/profile',
    ProfileEditPage: '/profile/edit',
    ProfileChangePasswordPage: '/profile/change-password',
    ProfileSettingsPage: '/profile/settings',

    HomePage: '/',


    AccountsPage:'/account',
    ChartOfAccountAllPage: '/account/chart-of-account',
    AddIncomePage: '/account/add-income',
    AddExpensePage: '/account/add-expense',
    AccountStatementPage: '/account/statement',


    ExaminationPortalPage: '/exams/:examIdFromUrl',
    ExaminationResultPortalPage: '/exams/:examIdFromUrl/result',
    /** 
     * GeneralSettings
    */
    GeneralSettingsPage: '/profile',
    InstitutionFeeParticularsPage: '/institute/feeparticulars',
    InstitutionProfilePage: '/institute/profile',
    InstitutionDetailsForFeeChallanPage: '/institute/details-for-fee-challan',
    InstitutionRulesAndRegulationsPage: '/institute/rules-and-regulations',
    InstitutionMarksGradingPage: '/institute/marks-grading',
    InstitutionAccountSettingsPage: '/institute/account-settings',
    InstitutionAcademicSettingsPage: '/institute/acedemic-settings',

    EmployeesPage: '/employee',
    EmployeePage: '/employee/:id',
    EmployeeAllPage: '/employee/list',
    EmployeeAddNewPage: '/employee/add',
    EmployeeStaffIdCardsPage: '/employee/staff-idcards',
    EmployeeJobLetterPage: '/employee/job-letter',

    ExamsPage: '/exam',
    ExamPage: '/exam/:id',
    ExamEditPage: '/exam/:id/edit',
    ExamAllPage: '/exam/list',
    ExamAddNewPage: '/exam/add',
    ExamResultPage: '/exam/result',
    ExamMarkingPage: '/exam/:id/marking',


    ExamGroupEditPage: '/exam/group/:id/edit',
    ExamGroupAllPage: '/exam/group/list',
    ExamGroupAddNewPage: '/exam/group/add',
    AssignExamToGroupPage: '/exam/assign-exam-to-group',

    MessagesPage: '/message',
    ChatRoomPage: '/message/:room_id',
    LiveClassRoomPage: '/message/live-class-room',
    DirectMessagingPage: 'message/dm',
    MeetingPage: '/meeting/:meeting_id',



    TestPortalPage: '/tests/:testIdFromUrl',
    TestResultPortalPage: '/tests/:testIdFromUrl/result',

    TestsPage: '/test',
    TestPage: '/test/:id',
    TestEditPage: '/test/:id/edit',
    TestAllPage: '/test/list',
    TestAddNewPage: '/test/add',
    TestResultPage: '/test/result',
    TestMarkingPage: '/test/:id/marking',


    TestGroupEditPage: '/test/group/:id/edit',
    TestGroupAllPage: '/test/group/list',
    TestGroupAddNewPage: '/test/group/add',
    AssignTestToGroupPage: '/test/assign-test-to-group',



    AssessmentsPage: '/assessment',
    AssessmentPage: '/assessment/:id',
    AssessmentEditPage: '/assessment/:id/edit',
    AssessmentAllPage: '/assessment/list',
    AssessmentAddNewPage: '/assessment/add',
    AssessmentResultPage: '/assessment/result',
    AssessmentMarkingPage: '/assessment/:id/marking',


    AssessmentGroupEditPage: '/assessment/group/:id/edit',
    AssessmentGroupAllPage: '/assessment/group/list',
    AssessmentGroupAddNewPage: '/assessment/group/add',
    AssignAssessmentToGroupPage: '/assessment/assign-assessment-to-group',

    AssessmentPortalPage: '/assessments/:assessmentIdFromUrl',
    AssessmentResultPortalPage: '/assessments/:assessmentIdFromUrl/result',

    StudentAdmissionLetterPage: '/student/admission-letter',
    StudentIdCardPage: '/student/idcard',
    StudentPromotionPage: '/student/promotion',
    StudentAddNewPage: '/student/add',
    StudentFamiliesPage: '/student/families',
    StudentAllPage: '/student/list',
    StudentPage: '/student/:id',
    StudentEditPage: '/student/:id/edit',


    ClassesPage: '/employee',
    ClassPage: '/class/:id',
    ClassAllPage: '/class/list',
    ClassAddNewPage: '/class/add',
    ClassEditPage: '/class/:id/edit',

    SubjectPage: '/subject',
    ClassWithSubjectsPage: '/subject/class-with-subjects',
    AssignClassToSubjectsPage: '/subject/assign-class-to-subjects',
    // EmployeeStaffIdCardsPage:'/employee/staff-idcards',
    // EmployeeJobLetterPage:'/employee/job-letter',




    TimetablesPage: '/timetable',
    WeekdaysTimetablePage: '/timetable/weekdeys',
    TimePeriodTimetablePage: '/timetable/timeperiod',
    ClassRoomsTimetablePage: '/timetable/classroom',

    CreateTimetablePage: '/timetable/create',
    ClassTimetablePage: '/timetable/class',
    TeacherTimetablePage: '/timetable/teacher',
    ActiveClassTimetablePage: '/timetable/class/active',


    WallePage: '/wallet',
    WalletFundingPage: '/wallet/funding',
    ReportsPage: '/report',
    ReportCardListPage: '/report/report-card/list',
    ReportCardPage: '/report/report-card',
    ReportCardSharelinkPage: '/i/report-card/:id/sharelink',

    ReportCardTemplatesPage: '/report/report-card/templates',
    ThirdTermReportCardPage: '/report/report-card/ThirdTerm',
    ThirdTermReportCardEditPage: '/report/report-card/ThirdTerm/:id/edit',
    ThirdTermReportCardPreviewPage: '/report/report-card/ThirdTerm/:id/preview',



    SecondTermReportCardPage: '/report/report-card/SecondTerm',
    SecondTermReportCardEditPage: '/report/report-card/SecondTerm/:id/edit',
    SecondTermReportCardPreviewPage: '/report/report-card/SecondTerm/:id/preview',
 
 
    FirstTermReportCardPage: '/report/report-card/FirstTerm',
    FirstTermReportCardEditPage: '/report/report-card/FirstTerm/:id/edit',
    FirstTermReportCardPreviewPage: '/report/report-card/FirstTerm/:id/preview',

    reportCardAction:(id,template, action)=> `/report/report-card/${template}/${id}/${action}`,

 
    'AttendancesPage': '/attendance/',
    'MarkStudentsAttendancePage': '/attendance/mark-students',
    'MarkEmployeesAttendancePage': '/attendance/mark-employees',
    'ClasswiseReportPage': '/attendance/class',
    'EmployeesAttendanceReportPage': '/attendance/employee',
    'StudentsAttendanceReportPage': '/attendance/student',


    ParentsPage:'/parent',
    ParentAllPage:'/parent/list',
    ParentAddNewPage:'/parent/add',
    ParentIdCardsPage:'/parent/id-cards',
    ParentPage:'/parent/:id',

    ManageFamiliesPage: '/families',
    HomeworksPage:'/homework',
    HomeworkAllPage:'/homework/list',
    HomeworAddNewPage:'/homework/add',
    HomeworkMarkPage:'/homework/:id/mark',
    HomeworkSubmitPage:'/homework/:id/submission',
    HomeworkPage:'/homework/:id',
})
export default routes
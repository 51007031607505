/**
 * 
 * @param {*} account 
 * @param {import('src/model/profile').Profile} profile 
 * @returns 
 */
export function getAccountFullName(account, profile, fallback='') {
    switch(account?.role?.type){
      case 'institute': return profile.get('Institute Name', account.username?? fallback);
      case 'student': return profile.get('Student Name', account.username?? fallback);
      case 'employee': return profile.get('Employee Name', account.username?? fallback);
      default: return  account?.name ?? account?.username ?? fallback;
    }
}
/**
 * 
 * @param {*} account 
 * @param {import('src/model/profile').Profile} profile 
 * @returns 
 */
export function  getAccountPhotoURL(account, profile , fallback=null) {
    switch(account?.role?.type){
      case 'institute': return profile.get('Avatar Url', fallback);
      case 'student': return profile.get('Avatar Url', fallback);
      case 'employee': return profile.get('Avatar Url', fallback);
      case 'parent': return profile.get('Avatar Url', fallback);
      default: return null;
    }
}